<template>
    <div class="nav-main">
        <div :class="navSiteCssArr">
            <div class="nav-left">
                <router-link to="/">
                    <img src="../../../../assets/img/admin-logo.png">
                </router-link>
            </div>
            <div class="nav-right">
                    <ul>
                        <li v-for="(item,index) in $webConfig.indexNav" :key="index">
                            <router-link :to="item.to" :title="item.title" v-if="!item.isNewPage">{{item.name}}</router-link>
                            <a v-if="item.isNewPage" :title="item.title" target="_blank" :href="item.to" >{{item.name}}</a>
                        </li>
                        <li class="bilibili">
                            <a
                                    :href="$webConfig.bilibili1.url"
                                    :title="$webConfig.bilibili1.title"
                                    target="_blank"
                            >
                                <img src="@/assets/img/bilibili-w.png" style="height: 100%">
                            </a>
                            <a
                                    :href="$webConfig.bilibili2.url"
                                    :title="$webConfig.bilibili2.title"
                                    target="_blank"
                            >
                                <img src="@/assets/img/bilibili-w.png" style="height: 100%">
                            </a>
                        </li>
                    </ul>
            </div>
            <div class="m-clear-both"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "nav",
        data() {
            return {
                navSiteCssArr: ["nav-site"]
            }
        },
        methods: {
            /**
             * 滚动条吸顶特效
             */
            handleScroll() {
                var that = this;
                window.onscroll = function () {
                    let scrollHeight = document.documentElement.scrollTop;
                    if (scrollHeight >= 28) {
                        that.navSiteCssArr.push("nav-scroll-top");
                    }
                    if (scrollHeight <= 0) {
                        for (let i = 0; i < that.navSiteCssArr.length; i++) {
                            if (that.navSiteCssArr[i] == "nav-scroll-top") {
                                that.navSiteCssArr.splice(i);
                            }
                        }
                    }
                }
            }
        },
        created() {
            this.handleScroll();
        }
    }
</script>

<style scoped>
    .nav-main{
        height: 100px;
        padding:28px 0;
        width: 100%;
    }
    .nav-site{
        height: 48px;
        background-color: #111111;
        width: 100%;
        line-height: 48px;
    }
    .nav-scroll-top{
        position: fixed;
        z-index: 1000;
        top: 0;
    }
    .nav-left{
        float: left;
        padding-left: 40px;
        height: 48px;
    }
    .nav-left img{
        max-height: 50px;
    }
    .nav-right{
        float: right;
        height: 48px;
        width: auto;
        padding-right: 40px;
    }
    .nav-right ul{
        display: block;
        height: 48px;
        padding: 0 0;
    }
    .nav-right li{
        float: left;
        list-style: none;
    }
    .nav-right a{
        display: block;
        height: 46px;
        text-align: center;
        color: white;
        width: 100px;
        padding: 0 10px;
        line-height: 46px;
        text-decoration: none;
        font-size: 16px;
    }
    @media screen and ( max-width: 1388px){
        .nav-right a{
            width: 60px !important;
            font-size: 14px;
        }
    }

    .bilibili img{
        max-height: 38px;
        margin-top: 4px;
    }
    .bilibili a{
        display: block;
        float: left;
        width: 50px
    }

</style>
