import Layout from '../views/admin/layout/index'


const RouterAdmin = [
    {
        // 登录
        path: '/admin/login',
        name: 'login',
        components: {
            default:() => import('../views/admin/login/index'),
            h5:() => import('../views/admin/login/index')
        },
    },
    {
        // 首页
        path: '/admin',
        components : {
            default:Layout,
            h5:Layout
        },
        children  : [
            {
                path:   'index',
                name:   'index',
                component : () => import('../views/admin/index/index')
            },
            {
                path:   'resetPass',
                name:   'resetPass',
                component : () => import('../views/admin/reset-pass/index')
            },
            {
                path:   'article',
                name:   'article',
                component : () => import('../views/admin/article/index')
            },
            {
                path:   'articleAdd',
                name:   'articleAdd',
                component : () => import('../views/admin/article/add')
            },
            {
                path:   'articleEdit',
                name:   'articleEdit',
                component : () => import('../views/admin/article/edit')
            },
            {
                path:   'dayCards',
                name:   'dayCards',
                component : () => import('../views/admin/day-cards/index')
            },
            {
                path:   'dayCardsAdd',
                name:   'dayCardsAdd',
                component : () => import('../views/admin/day-cards/add')
            },
            {
                path:   'dayCardsSet',
                name:   'dayCardsSet',
                component : () => import('../views/admin/day-cards/card-set')
            },
            {
                path:   'products',
                name:   'products',
                component : () => import('../views/admin/products/index')
            },
            {
                path:   'productAdd',
                name:   'productAdd',
                component : () => import('../views/admin/products/add')
            },
            {
                path:   'productEdit',
                name:   'productEdit',
                component : () => import('../views/admin/products/edit')
            },
            {
                path:   'erattas',
                name:   'erattas',
                component : () => import('../views/admin/erattas/list')
            },
            {
                path:   'erattasAdd',
                name:   'erattasAdd',
                component : () => import('../views/admin/erattas/add')
            },
            {
                path:   'erattasEdit',
                name:   'erattasEdit',
                component : () => import('../views/admin/erattas/edit')
            },
            {
                path:   'recommendDecks',
                name:   'recommendDecks',
                component : () => import('../views/admin/recommend-decks/index')
            },
            {
                path:   'recommendDeckAdd',
                name:   'recommendDeckAdd',
                component : () => import('../views/admin/recommend-decks/add')
            },
            {
                path:   'recommendDeckEdit',
                name:   'recommendDeckEdit',
                component : () => import('../views/admin/recommend-decks/edit')
            },
        ]
    }

];


export default RouterAdmin
