<template>
    <div>
        <el-container>
            <el-header style="background-color: #192027;height: 6vh;line-height: 6vh">
                <Header></Header>
            </el-header>
            <el-container >
                <el-aside width="204px" style="padding-right: 1px;text-align: left;height: 94vh;background-color: #28333e">
                    <div>
                        <Menu/>
                    </div>
                </el-aside>
                <el-main style="padding: 0;height: 94vh;overflow: auto;background-color: #ecf0f5">
                    <!--<div style="height: 30px;border-bottom: 1px solid #dcdfe6;padding-top: 20px;padding-left: 5px" >
                        <Breadcrumb></Breadcrumb>
                    </div>-->
                    <div class="main-content" style="height: 80%">
                        <router-view/>
                    </div>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
    import Menu from "./components/menu"
    import Header from "./components/header"
    export default {
        name: "index",
        components:{Menu,Header},
        created() {
            document.title = "后台管理系统"
        }
    }
</script>

<style scoped>
    .main-content{
        padding: 20px;
        height: 100%;
    }

</style>
