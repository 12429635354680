import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import cookie from './plugins/cookie'
import 'normalize.css'
import './assets/css/main.css'
import webConfig from './webConfig'
import JsCookie from 'js-cookie'
import moment from 'moment'


Vue.config.productionTip = false;


router.beforeEach((to, from, next) => {

  // 设置title
  document.title = "《卡片战斗先导者》官方网站";
  // 权限设置
  var allowPath = [
    "/admin/login",
  ];
  var needAuth = true;

  if ((to.path).indexOf("admin") >= 0)
  {
    allowPath.forEach((v)=>{
      if ( to.path == v  ) {
        needAuth = false;
      }
    });
  }else{
    needAuth = false;
  }

  if (needAuth){
    let token = JsCookie.get("token");
    if (token == undefined || token == ""){
      router.push("/admin/login");
    }
  }

  next()
});

/**
 * 设置滚动条到顶部
 */
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
  // 或
  // window.scroll(0, 0);
});


// 配置项
Vue.prototype.$webConfig = webConfig;


// 格式化时间
Vue.prototype.$moment = moment;

new Vue({
  router,
  store,
  cookie,
  render: h => h(App)
}).$mount('#app');
