import Vue from 'vue'
import VueRouter from 'vue-router'
import RouterIndex from './routerIndex'
import RouterAdmin from './routerAdmin'

Vue.use(VueRouter);


const routes = [
  ...RouterIndex,
  ...RouterAdmin,
];



const router = new VueRouter({
  routes
});



export default router
