<template>
    <div class="index">
        <div style="max-height: 10vh;">
            <Nav></Nav>
        </div>
        <div style="min-height: 70vh">
            <router-view :key="$route.fullPath" :name="routerName"></router-view>
        </div>
        <div style="min-height: 20vh;">
            <Foot></Foot>
        </div>
        <!--固定浮动滚动顶部图标-->
        <el-backtop :bottom="20" :right="20" style="width: 62px;height: 62px;">
            <div
                    style="{
                    height: 100%;
                    width: 100%;
                    background-color: #111111;
                    text-align: center;
                    line-height: 62px;
                    color: white;
                    box-shadow: 0 2px 12px 0 rgba(1, 1, 2, 0.1);
                    font-size: 42px;
                    font-weight: bold;
                  }"
            >
                <i class="el-icon-arrow-up"></i>
            </div>
        </el-backtop>
    </div>
</template>

<script>
    import Nav from './components/nav'
    import Foot from './components/foot'
    export default {
        name: "layout",
        components:{Nav,Foot},
        data() {
            return {
                routerName: "default"
            }
        },
        methods: {
            getInfo() {
                this.$axios.post("site/siteConfig/get",{})
                    .then((res)=>{
                        if (res.data.code == 1000){
                            this.$store.state.webConf = res.data.data;
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    })
            },
            _isMobile() {
                let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
                return flag;
            }
        },
        created() {
            if (this._isMobile()){
                this.routerName = "h5"
            }
            this.getInfo();
        }
    }
</script>

<style scoped>
    .index{
        min-height: 100vh;
        background-image: url("../../../assets/img/bg_new.jpg");
    }
</style>
