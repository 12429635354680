"use strict";

import Vue from 'vue';
import axios from "axios";
import JsCookie from 'js-cookie'
import Setting from '../webConfig'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
console.log(process.env.NODE_ENV)



let config = {
  baseURL: Setting.serve.baseUrl,
  timeout: 20 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
  headers: {
    token: ""
  }
};

if (process.env.NODE_ENV == "development") {
  config.baseURL = Setting.serve.baseUrlDev
}

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function (Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        let token = JsCookie.get("token");
        config.headers.token = token;
        return axios.create(config);
      }
    },
    $axios: {
      get() {
        let token = JsCookie.get("token");
        config.headers.token = token;
        return axios.create(config);
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
