<template>
    <div class="foot">
        <div class="line-nav">
            <el-divider>
                <a :href="$webConfig.bilibili1.url" :title="$webConfig.bilibili1.title" target="_blank">
                    <img src="../../../../assets/img/bilibili.png">
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a :href="$webConfig.bilibili2.url" :title="$webConfig.bilibili2.title" target="_blank">
                    <img src="../../../../assets/img/bilibili.png">
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a :href="$webConfig.weibo1.url" :title="$webConfig.weibo1.title" target="_blank">
                    <img src="../../../../assets/img/weibo.png">
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a :href="$webConfig.weibo2.url" :title="$webConfig.weibo2.title" target="_blank">
                    <img src="../../../../assets/img/weibo.png">
                </a>
            </el-divider>
        </div>
        <div class="link-nav">
            <div>
                <span v-for="(item, index) in $webConfig.indexNav" :key="index">
                    <router-link :to="item.to" :title="item.title" v-if="!item.isNewPage">{{ item.name }}</router-link>
                    <a v-if="item.isNewPage" :title="item.title" target="_blank" :href="item.to">{{ item.name }}</a>
                    <br v-if="index == 5">
                </span>
            </div>
        </div>
        <div class="link">
            <div class="link-div">
                <ul>
                    <li v-for="item in $store.state.webConf.links" :key="item.img">
                        <a :href="item.url"><img :src="item.img"></a>
                    </li>
                </ul>
            </div>
            <div class="m-clear-both"></div>
        </div>
        <div class="version">{{ $store.state.webConf.version }}</div>
    </div>
</template>

<script>
export default {
    name: "foot",
};
</script>

<style scoped>
.link-nav {
    min-height: 100px;
}

.link-nav>div {
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    text-align: center;
    word-wrap: break-word
}

.link-nav a {
    text-decoration: none;
    color: white;
    /*float: left;*/
    margin: 10px 20px;
    line-height: 40px;
    letter-spacing: 3px;
    /*height: 40px;
        line-height: 40px;
        width: 80px;
        text-align: center;
        margin-left: 40px;*/
}

.line-nav {
    margin: 50px 0;
}

.line-nav img {
    width: 40px;
}

.link {
    height: auto;
    text-align: center;
}

.link-div {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.link-div>ul {
    text-decoration: none;
}

.link-div li {
    text-decoration: none;
    display: block;
    width: 200px;
    height: 40px;
    float: left;
    margin: 5px;
}

.link-div li img {
    max-width: 100%;
    max-height: 100%;
}

.foot {
    min-height: 100%;
    background-color: #111111;
    color: #ffffff;
    padding: 10px 0;

}

.version {
    clear: both;
    font-size: 12px;
    text-align: center;
    padding: 48px;
    height: 44px;
    line-height: 22px;
}
</style>
