<template>
    <div class="nav-main">
        <div class="nav-site">
            <div class="nav-left">
                <router-link to="/">
                    <img src="../../../../assets/img/admin-logo.png">
                </router-link>
                <i class="el-icon-s-fold" style="font-size: 50px;float: right" @click="showRightNav"></i>
            </div>
            <div class="nav-right m-clear-both">
                    <ul>
                        <li v-for="(item,index) in $webConfig.indexNav" :key="index">
                            <router-link :to="item.to" :title="item.title" v-if="!item.isNewPage">{{item.name}}</router-link>
                            <a v-if="item.isNewPage" :title="item.title" target="_blank" :href="item.to" >{{item.name}}</a>
                        </li>
                        <li class="bilibili">
                            <a
                                    :href="$webConfig.bilibili1.url"
                                    :title="$webConfig.bilibili1.title"
                                    target="_blank"
                            >
                                <img src="@/assets/img/bilibili-w.png" style="height: 100%">
                            </a>
                            <a
                                    :href="$webConfig.bilibili2.url"
                                    :title="$webConfig.bilibili2.title"
                                    target="_blank"
                            >
                                <img src="@/assets/img/bilibili-w.png" style="height: 100%">
                            </a>
                        </li>
                    </ul>
            </div>
            <div class="m-clear-both"></div>
        </div>

        <el-drawer
                :visible.sync="rightNav"
                size="40%"
        >
            <div class="right-nav">
                <ul>
                    <li @click="closeNav">
                        <router-link
                                to="/"
                        >首页</router-link>
                    </li>
                    <li v-for="(item,index) in $webConfig.indexNav" :key="'right'+index" @click="closeNav">
                        <router-link
                                :to="item.to"
                                :title="item.title"
                                v-if="!item.isNewPage"
                        >{{item.name}}</router-link>
                        <a v-if="item.isNewPage" :title="item.title" target="_blank" :href="item.to" >{{item.name}}</a>
                    </li>
                </ul>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    export default {
        name: "nav",
        data() {
            return {
                rightNav:false
            }
        },
        methods: {
            showRightNav(){
                this.rightNav = true
            },
            closeNav(){
                this.rightNav = false;
            }
        },
        created() {

        }
    }
</script>

<style scoped>
    .nav-main{
        height: 100px;
        padding:28px 0;
        white-space: nowrap;
    }
    .nav-site{
        height: 48px;
        width: 100%;
        line-height: 48px;
        position: fixed;
        z-index: 1000;
        top: 0;
    }
    .nav-scroll-top{
        position: fixed;
        z-index: 1000;
        top: 0;
    }
    .nav-left{
        padding-left: 10px;
        height: 48px;
        z-index: 2000;
        background-image:url("../../../../assets/img/bg_new.jpg") ;
    }
    .nav-left img{
        max-height: 50px;
    }
    .nav-right{
        height: 48px;
        background-color: #111111;
        line-height: 48px;
        overflow-y: scroll;
        overflow-x: scroll;
    }
    .nav-right ul{
        /*display: block;*/
        height: 48px;
        padding: 0 0;
        overflow-y: scroll;
        overflow-x: scroll;
        width: 800px;
    }
    .nav-right li{
        display: block;
        float: left;
        list-style: none;
        line-height: 48px;
    }
    .nav-right a{
        display: block;
        height: 46px;
        text-align: center;
        color: white;
        width: 60px;
        padding: 0 10px;
        line-height: 46px;
        text-decoration: none;
        font-size: 14px;
    }

    .bilibili img{
        max-height: 38px;
        margin-top: 4px;
    }
    .bilibili a{
        display: block;
        float: left;
        width: 50px
    }
    .right-nav{
        background-color: #111111;
        padding: 10px 0 ;
    }
    .right-nav ul{
        padding: 0;
        margin: 0;
        text-align: center;
    }
    .right-nav li{
        list-style: none;
        line-height: 48px;
        display: block;
        padding: 0;
        margin: 0;

    }
    .right-nav a{
        display: block;
        height: 48px;
        width: 100%;
        text-decoration: none;
        color: white;
    }
</style>
