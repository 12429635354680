<template>
    <div>
        <div class="header" style="height: 8vh">
            <div class="logo" style="height: 8vh;line-height: 8vh">
                <img src="@/assets/img/admin-logo.png" alt="logo" style="height: 60%;margin-top: 1%">
            </div>

            <div class="user">
                <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link" style="color: white;">
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                    <el-dropdown-menu slot="dropdown" >
                        <el-dropdown-item command="modifyPass">修改密码</el-dropdown-item>
                        <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="userLogo">
                <div class="userLogoImg">
                    <img src="@/assets/img/header.png">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import JsCookie from 'js-cookie'
    export default {
        name: "header",
        methods:{
            handleCommand(command) {
                switch (command) {
                    case "loginOut":
                        this.$confirm("是否确定退出登录?")
                            .then(()=>{
                                JsCookie.set("token","");
                                setTimeout(()=>{
                                    this.$router.push("/admin/login");
                                },500);
                            });
                        break;
                    case "modifyPass":
                        this.$router.push("/admin/resetPass");
                        break;
                    default:
                }
            },
        },
        data:function () {
            return {
                dialogFormVisible: false,
                user:{},
            }
        },

    }
</script>

<style scoped>
    .header{
    }
    .logo{
        color: white;
        float: left;
    }
    .user{
        color: white;
        float: right;
        width: auto;
        margin-left: 20px;
        text-align: center;
    }
    .userLogo {
        float: right;
        height: 100%;
        width: 100px;
        line-height: 100%;
        position: relative;
        object-fit: contain ;
    }
    .userLogoImg{
        border-radius: 50%;
        height: 38px;
        width: 38px;
        top: 13%;
        left: 64%;
        position: relative;
    }
    .userLogo img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

</style>
