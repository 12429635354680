import IndexLayout from '../views/index/layout/layout'
import H5IndexLayout from '../views/h5/layout/layout'

const RouterIndex = [
    {
        path: '/',
        name: 'index',
        components: {
            default:IndexLayout,
            h5     :H5IndexLayout
        },
        children:[
            {
                // 首页
                path:"/",
                components:{
                    default:() => import('../views/index/main/index'),
                    h5:() => import('../views/h5/main/index')
                }
            },
            {
                // 文章
                path:"/index/article",
                components:{
                    default:() => import('../views/index/article/index'),
                    h5:() => import('../views/h5/article/index')
                }
            },
            {
                // 文章列表
                path:"/index/articleList",
                components:{
                    default:() => import('../views/index/article/list'),
                    h5:() => import('../views/h5/article/list')
                }
            },
            {
                // 卡牌清单
                path:"/index/cardList",
                components:{
                    default:() => import('../views/index/card-list/index'),
                    h5:() => import('../views/h5/card-list/index')
                }
            },
            {
                // 卡牌详情
                path:"/index/cardDetail",
                components:{
                    default:() => import('../views/index/card-list/detail'),
                    h5:() => import('../views/h5/card-list/detail')
                }
            },
            {
                // 卡组清单
                path:"/index/deck",
                components:{
                    default:() => import('../views/index/deck/index'),
                    h5:() => import('../views/h5/deck/index')
                }
            },
            {
                // 卡组详情
                path:"/index/deckDetail",
                components:{
                    default:() => import('../views/index/deck/detail'),
                    h5:() => import('../views/h5/deck/detail')
                }
            },
            {
                // 店家清单
                path:"/index/store",
                components:{
                    default:() => import('../views/index/store/index'),
                    h5:() => import('../views/h5/store/index')
                }
            },
            {
                // 比赛清单
                path:"/index/tournament",
                components:{
                    default:() => import('../views/index/tournament/index'),
                    h5:() => import('../views/h5/tournament/index')
                }
            },
            {
                // 比赛详情
                path:"/index/tournamentDetail",
                components:{
                    default:() => import('../views/index/tournament/detail'),
                    h5:() => import('../views/h5/tournament/detail')
                }
            },
            {
                // 每日一卡详情
                path:"/index/dayCardDetail",
                components:{
                    default:() => import('../views/index/day-card/detail'),
                    h5:() => import('../views/h5/day-card/detail')
                }
            },
            {
                // 每日一卡列表
                path:"/index/dayCardList",
                components:{
                    default:() => import('../views/index/day-card/list'),
                    h5:() => import('../views/h5/day-card/list')
                }
            },
            {
                // 产品信息
                path:"/index/productInfo",
                components:{
                    default:() => import('../views/index/product/info'),
                    h5:() => import('../views/h5/product/info')
                }
            },
            {
                // 产品列表
                path:"/index/productList",
                components:{
                    default:() => import('../views/index/product/list'),
                    h5:() => import('../views/h5/product/list')
                }
            },
            {
                // 勘误列表
                path:"/index/erattaList",
                components:{
                    default:() => import('../views/index/eratta/list'),
                    h5:() => import('../views/h5/eratta/list')
                }
            },
            {
                // 勘误列表
                path:"/index/erattaInfo",
                components:{
                    default:() => import('../views/index/eratta/info'),
                    h5:() => import('../views/h5/eratta/info')
                }
            }
        ]
    }
];

export default RouterIndex
