<template>
    <div>
        <el-row class="tac">
            <el-menu
                    default-active="0"
                    class="el-menu-vertical-demo"
                    @open="handleOpen"
                    @close="handleClose"
                    background-color="#28333e"
                    text-color="#bfbbbb"
                    active-text-color="#ffffff"
                    router
                    :default-openeds="opens"
            >

                <el-submenu :index="row.path" v-for="row in authList" :key="row.path">
                    <template slot="title">
                        <i class="el-icon-location"></i>
                        <span>{{row.name}}</span>
                    </template>
                    <el-menu-item :index="'/admin/'+subRow.path" v-for="subRow in row.sub" :key="subRow.path">{{subRow.name}}</el-menu-item>
                </el-submenu>


            </el-menu>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: "menu",
        data:function(){
            return {
                authList : [],
                opens:[]
            }
        },
        methods: {
            handleOpen(key, keyPath) {
                console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                console.log(key, keyPath);
            },
            getAuthMenu:function () {
                this.$axios.post("site/user/authMenu")
                    .then((res)=>{
                        if (res.data.code == 1000){
                            let data = res.data.data;
                            this.authList = data.authList;
                            this.$store.state.user = data.user;
                            // 设置菜单默认展开
                            this.setOpens();
                        } else{
                            this.$message.error(res.data.msg);
                        }
                    })
            },
            setOpens:function () {
                var that = this;
                this.authList.forEach(function (v,k) {
                    that.opens.push(v.path);
                })
            }
        },
        created() {
            this.getAuthMenu();

        },
    }
</script>

<style scoped>
    .el-menu{
        border-right: none ;
    }
</style>
